/*
.services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.4s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .services .icon-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 35px 0 rgba(68, 88, 144, 0.2);
  }
  
  .services .icon {
    position: absolute;
    left: -20px;
    top: calc(50% - 30px);
  }
  
  .services .icon i {
    font-size: 64px;
    line-height: 1;
    transition: 0.5s;
  }
  
  .services .title {
    margin-left: 40px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title span {
    color: #2a2c39;
    transition: ease-in-out 0.3s;
  }
  
  .services .title span:hover {
    color: #ef6603;
  }
  
  .services .description {
    font-size: 14px;
    margin-left: 40px;
    line-height: 24px;
    margin-bottom: 0;
  }
   */
   /*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 50px 20px;
  margin-top: 35px;
  margin-bottom: 25px;
  text-align: center;
  border: 1px solid #ebe6e4;
  height: 220px;
  position: relative;
  background: #faf9f8;
}

.services .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  border-radius: 50%;
  border: 6px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #fdc134;
}

.services .icon i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.services .title a {
  color: #343a40;
}
.services .icon-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 2px 35px 0 rgba(68, 88, 144, 0.2);
}
.services .icon-box:hover .icon {
  background: #fff;
  border: 2px solid #fdc134;
}

.services .icon-box:hover .icon i {
  color: #fdc134;
}

.services .icon-box:hover .title a {
  color: #f03c02;
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}
.grad1 {
  height: 20px;
  width: 20px;
  background-color: orange; /* For browsers that do not support gradients */
  background-image: conic-gradient(yellow, blue, green, orange,  
  								   yellow, blue, green, orange);
  border-radius:50%;
  display: block;
}