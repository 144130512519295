.maincontainer{
  position: relative;
  width: 100%;
  display: block;
}
.back{
    position: absolute;
    width: 100%;
    display: block;
    height:100%;
   
}
.wavesBg{
   
    rotate: 180deg;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
.front{
    position: absolute;
    width: 100%;
    display: block;
    height:100%;
   
}

/* style the maincontainer class with all child div's of class .front */
.maincontainer > .front
{
    position: absolute;
    transform: perspective(600px) rotateY(0deg);
    backface-visibility: hidden; /* cant see the backside elements as theyre turning around */
    transition: transform .5s linear 0s;
}

/* style the maincontainer class with all child div's of class .back */
.maincontainer > .back
{
    position: absolute;
    transform: perspective(600px) rotateY(180deg);
    border-radius: 10px;
    padding: 5px;
    backface-visibility: hidden; /* cant see the backside elements as theyre turning around */
    transition: transform .5s linear 0s;
}

.maincontainer:hover > .front
{
    transform: perspective(600px) rotateY(-180deg);
}

.maincontainer:hover > .back
{
    transform: perspective(600px) rotateY(0deg);
}
.bg-cardBG{
    background-color: #a5e9ffad;
}