.hero {
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/img/bg_water.svg") top center;
  background-size: cover;
  position: relative;
  padding: 80px 0;
  z-index: 3;
}
.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
  z-index: -1; /* Places the video behind other content */
}
@media (min-width: 1034px) {
  .hero:after {
    position: absolute;
    content: "";
    width: 40%;
    background: #1b2f45;
    top: 0;
    bottom: 0;
  }
}

.hero:before {
  position: absolute;
  content: "";
  background: rgba(27, 47, 69, 0.6);
  inset: 0;
}

@media (max-width: 1034px) {
  .hero:before {
    background: rgba(27, 47, 69, 0.7);
  }
}

.hero .container {
  z-index: 1;
}

@media (min-width: 1365px) {
  .hero {
    background-attachment: fixed;
  }
}

.hero h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  font-family: var(--font-secondary);
}

.hero blockquote {
  color: #fff;
  padding-left: 20px;
  font-size: 18px;
  font-family: var(--font-default);
  border-left: 2px solid #56b8e6;
  margin: 20px 0;
}

.hero .btn-get-started {
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: var(--color-primary);
}

.hero .btn-get-started:hover {
  background: rgba(86, 184, 230, 0.8);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
}

.hero .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover {
  color: #fff;
}

.hero .btn-watch-video:hover i {
  color: #82cbed;
}

@media (max-width: 640px) {
  .hero h2 {
    font-size: 36px;
    line-height: 1;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 13px;
  }
}