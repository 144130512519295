
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: linear-gradient(0deg, rgb(17 24 39) 0%, rgb(11 20 82) 100%);
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 0 0 30px 0;
  }
  
  #footer h3 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    position: relative;
    padding: 0;
    margin: 0 0 15px 0;
  }
  
  #footer p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
  }
  
  #footer .social-links {
    margin: 0 0 40px 0;
  }
  
  #footer .social-links a {
    font-size: 24px;
    display: inline-block;
    background: #4680ff;
    color: #fff;
    line-height: 1;
    padding: 6px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: #fff;
    color: #4680ff;
    text-decoration: none;
  }
  
  #footer .copyright {
    margin: 0 0 5px 0;
  }
  
  #footer .credits {
    font-size: 13px;
  }
  